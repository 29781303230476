import React from "react";
import { Facebook, Twitter, LinkedIn, GitHub } from "@mui/icons-material";
import CenterMenu from "./CenterMenu";
const Footer = () => {
  const socialStyle =
    "border-[0.5px] rounded-[10px] border-[#c1bdbd99] p-[2px] bg-[#081730] ";
  return (
    <div className="footer h-[45rem] flex flex-col justify-center items-center bg-[#081730] mt-[-12rem] px-[4rem] py-[4rem]">
      <CenterMenu />

      <div className="socialIcons flex mt-[2rem]">
        <div className={socialStyle + `facebook mr-[0.8rem] cursor-pointer`}>
          <Facebook />
        </div>
        <div className={socialStyle + `twitter mr-[0.8rem] cursor-pointer`}>
          <Twitter />
        </div>
        <div className={socialStyle + `linkedin mr-[0.8rem] cursor-pointer`}>
          <LinkedIn />
        </div>
        <div className={socialStyle + `github cursor-pointer`}>
          <GitHub />
        </div>
      </div>

      <span className="text-[13px] text-[#707070]  mt-[2rem] text-center mb-[3rem]">
        Suspendisse posuere ipsum sed rhoncus commodo. <br /> Vestibulum cursus
        eu sem eget condimentum. <br />
      </span>
    </div>
  );
};

export default Footer;
