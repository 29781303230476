import React from "react";
import android from "../img/Google Play.png";
import ios from "../img/App Store.png";

const DownloadAds = () => {
  return (
    <div className="downloadAds flex w-[9.5rem] h-[2.6rem] rounded-[3rem]">
      <img src={android} alt="" className="mr-[2rem]" />
      <img src={ios} alt="" />
    </div>
  );
};

export default DownloadAds;
