import React from "react";

const Features = ({ icon, title }) => {
  return (
    <div className="features flex flex-col items-center justify-center mr-[2rem]">
      <div>
        <img
          src={icon}
          alt=""
          className="w-[5rem] h-[5rem] bg-[#081730] p-[10px] rounded-[0.5rem]"
        />
      </div>
      <span className=" mt-[2rem]">{title}</span>
      <span className="text-[#707070]  mt-[2rem] text-center">
        Nulla aliquet est id accumsan pulvinar. Curabitur at nulla velit.
        Praesent tempus sapien euismod neque mollis, sed condimentum nulla
        aliquam.
      </span>

      <span className="text-[#E600FF] underline mt-[3rem] hover:cursor-pointer">
        Learn more
      </span>
    </div>
  );
};

export default Features;
