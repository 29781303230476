import React from "react";
import logo from "../img/MuzicLogo.png";
import CenterMenu from "./CenterMenu";

const Header = () => {
  const btStyle =
    "border-[2px] rounded-[10px] border-[#232A4E] px-[25px] py-[7px]";
  return (
    <div className="header flex justify-between bg-[#081730] px-[5rem] pt-[2.4rem] items-center text-[0.8rem]">
      <img src={logo} alt="" className="logo w-[42px] h-[42px]" />
      <CenterMenu />
      <div className="buttons flex">
        <button className={` mr-[4px] hover:bg-[#232A4E] ` + btStyle}>
          Login
        </button>
        <button className={btStyle + ` bg-[#232A4E] `}>Sign up</button>
      </div>
    </div>
  );
};

export default Header;
