import React from "react";

const CenterMenu = () => {
  const liStyle = "mr-[2.5rem] hover:cursor-pointer";
  return (
    <div className="menu">
      <ul className="flex width-[100%] justify-between">
        <li className={liStyle}>Home</li>
        <li className={liStyle}>About</li>
        <li className={liStyle}>Performer</li>
        <li className={liStyle}>Event Shedule</li>
      </ul>
    </div>
  );
};

export default CenterMenu;
