import React from "react";
import DownloadAds from "./DownloadAds";
import backGraphics from "../img/backgraphics.png";
import img1 from "../img/p 1.png";
import img2 from "../img/p 2.png";
import img3 from "../img/p 3.png";
import img4 from "../img/p 4.png";

const Hero = () => {
  return (
    <div className="wrapper flex items-center px-[5rem] justify-between bg-[#081730] rounded-b-[5rem] w-[100%] h-[35rem] relative z-[3]">
      <div className="headings flex flex-col text-[3rem] items-start h-[100%] justify-center">
        <span>Experience The</span>
        <span>
          <b>Best Quality Music</b>
        </span>
        <span className="text-[15px] text-[#525D6E]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          <br />
          elementum nulla tortor, non posuere elit mollis et.
        </span>

        <div>
          <span className="text-[13px]">Download now on IOS and Android</span>
          <DownloadAds />
        </div>
      </div>
      <div className="images relative w-[50%]">
        <img src={backGraphics} alt="" className="absolute top-[-8rem] " />
        <img
          src={img1}
          alt=""
          className="absolute top-[-15rem] left-[13rem] h-[33rem] "
        />
        <img
          src={img2}
          alt=""
          className="absolute top-[83px] left-[230px] w-[175px] "
        />
        <img
          src={img3}
          alt=""
          className="absolute top-[11rem] left-[13rem] w-[5rem] "
        />
        <img
          src={img4}
          alt=""
          className="absolute top-[11rem] left-[12.5rem] w-[5rem] "
        />
      </div>
    </div>
  );
};

export default Hero;
