import React from "react";
import MusicPlayer from "./MusicPlayer";

const Search = () => {
  return (
    <div className="search flex justify-center items-center h-[64rem] bg-[#081730] px-[5rem] pt-[18rem] pb-[10rem] rounded-b-[5rem] relative z-[1] mt-[-15rem]">
      <div className="left flex-1 mr-[3rem]">
        <img
          src={require("../img/backgraphics.png")}
          alt=""
          className="absolute top-[22rem] left-[-47rem]"
        />
        <img
          src={require("../img/d1.png")}
          alt=""
          className="w-[16rem] top-[26rem] absolute left-[5rem]"
        />
        <img
          src={require("../img/d2.png")}
          alt=""
          className="absolute w-[9rem] top-[32.7rem] left-[7rem]"
        />
        <img
          src={require("../img/d3.png")}
          alt=""
          className=" w-[9rem] top-[33rem] left-[17rem] absolute"
        />
        <img
          src={require("../img/d4.png")}
          alt=""
          className="absolute w-[14rem] top-[50.8rem] left-[7rem]"
        />
      </div>
      <div className="right flex-1 flex flex-col justify-center align-center">
        <div className="searchBar flex w-[100%]">
          <input
            type="text"
            placeholder="Enter the keyword or URL"
            className="flex-[19] bg-[#020917] outline-none rounded-xl p-3 h-[3rem] mr-[2rem]"
          />
          <div className="searchIcon flex items-center rounded-xl p-4 h-[2.8rem] bg-gradient-to-bl from-[#F3071D] to-[#E600FF] w-fit-content">
            <img
              src={require("../img/search.png")}
              alt=""
              className="w-[1.5rem]"
            />
          </div>
        </div>

        <div className="text-[2rem] flex flex-col mt-[2rem] px-[1rem]">
          <span>Search Music By</span>
          <span>
            <b>Name or Direct URL</b>
          </span>

          <span className="text-[13px] text-[#4D586A] mt-[1rem]">
            In cursus elementum est, eget auctor mi vehicula ac. Duis vitae{" "}
            <br />
            varius leo, id laoreet eros. Etiam a tristique neque. Aenean <br />
            malesuada magna odio, id pretium nisi tincidunt tempor.
          </span>
        </div>

        <MusicPlayer />
      </div>
    </div>
  );
};

export default Search;
