import React from "react";

const MusicPlayer = () => {
  return (
    <div className="musicPlayer flex flex-col flex-1 bg-[#232A4E] px-5 py-3 border-[#000] border-2 width-[100%] rounded-xl mt-5">
      <div className="upper flex items-center justify-between">
        <div className="profile flex">
          <img
            src={require("../img/Mask Group 23.png")}
            alt=""
            className="w-[3rem] h-[3rem] border-[#fff] border-[2px] rounded-full"
          />
          <div className="details flex flex-col ml-[1rem]">
            <span>Summertime Sadness</span>
            <span className="text-[14px] text-[#4D586A]">Lana Del Rey</span>
          </div>
        </div>

        <img
          src={require("../img/path.png")}
          alt=""
          className="w-[0.3rem] h-[1.2rem] justify-end"
        />
      </div>
      <div className="lower flex items-center mt-[1rem] justify-between">
        <span className="mr-[1rem]">2:30</span>
        <img
          src={require("../img/Group 9.png")}
          alt=""
          className="w-[15rem] h-[2.5rem] mr-[1rem]"
        />
        <span>4:30</span>
        <div className="controls flex">
          <svg
            className="svg-icon mr-[2rem] "
            style={{
              width: "1.5em",
              height: "1.5em",
              verticalAlign: "middle",
              fill: "#4D586A",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M896 810.666667c-8.533333 0-17.066667-4.266667-25.6-8.533333l-384-256C477.866667 537.6 469.333333 524.8 469.333333 512s8.533333-25.6 17.066667-34.133333l384-256c12.8-8.533333 29.866667-8.533333 42.666667 0C930.133333 226.133333 938.666667 238.933333 938.666667 256l0 512c0 17.066667-8.533333 29.866667-21.333333 38.4C908.8 810.666667 904.533333 810.666667 896 810.666667zM588.8 512l264.533333 174.933333L853.333333 337.066667 588.8 512z" />
            <path d="M512 810.666667c-8.533333 0-17.066667-4.266667-25.6-8.533333l-384-256C93.866667 537.6 85.333333 524.8 85.333333 512s8.533333-25.6 17.066667-34.133333l384-256c12.8-8.533333 29.866667-8.533333 42.666667 0C546.133333 226.133333 554.666667 238.933333 554.666667 256l0 512c0 17.066667-8.533333 29.866667-21.333333 38.4C524.8 810.666667 520.533333 810.666667 512 810.666667zM204.8 512l264.533333 174.933333L469.333333 337.066667 204.8 512z" />
          </svg>

          <svg
            className="svg-icon  mr-[2rem]"
            style={{
              width: "2em",
              height: "2em",
              verticalAlign: "middle",
              fill: "#4D586A",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M512 967.111c-250.311 0-455.111-204.8-455.111-455.111S261.689 56.889 512 56.889 967.111 261.689 967.111 512 762.311 967.111 512 967.111z m0-56.889c221.867 0 398.222-176.355 398.222-398.222S733.867 113.778 512 113.778 113.778 290.133 113.778 512 290.133 910.222 512 910.222z"
              fill="#73777A"
            />
            <path d="M796.444 512l-455.11 227.556V284.444z" fill="#73777A" />
          </svg>

          <svg
            className="svg-icon  mr-[2rem]"
            style={{
              width: "1.5em",
              height: "1.5em",
              verticalAlign: "middle",
              fill: "#4D586A",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M128 810.666667c-8.533333 0-12.8 0-21.333333-4.266667C93.866667 797.866667 85.333333 785.066667 85.333333 768L85.333333 256c0-17.066667 8.533333-29.866667 21.333333-38.4 12.8-8.533333 29.866667-8.533333 42.666667 0l384 256c12.8 8.533333 17.066667 21.333333 17.066667 34.133333s-8.533333 25.6-17.066667 34.133333l-384 256C145.066667 806.4 136.533333 810.666667 128 810.666667zM170.666667 337.066667l0 354.133333 264.533333-174.933333L170.666667 337.066667z" />
            <path d="M512 810.666667c-8.533333 0-12.8 0-21.333333-4.266667C477.866667 797.866667 469.333333 785.066667 469.333333 768L469.333333 256c0-17.066667 8.533333-29.866667 21.333333-38.4 12.8-8.533333 29.866667-8.533333 42.666667 0l384 256c12.8 8.533333 17.066667 21.333333 17.066667 34.133333s-8.533333 25.6-17.066667 34.133333l-384 256C529.066667 806.4 520.533333 810.666667 512 810.666667zM554.666667 337.066667l0 354.133333 264.533333-174.933333L554.666667 337.066667z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
