import React from "react";
import Features from "./Features";
const Experience = () => {
  return (
    <div className="experience flex flex-col items-center justify-start px-[5rem] h-[60rem] pt-[18rem] mt-[-10rem] relative z-[2]  rounded-b-[5rem] bg-[#020917]">
      <img src={require("../img/Path 318.png")} alt="" className="w-[5rem]" />
      <div className="headline flex flex-col mt-7 items-center text-[2rem]">
        <span>An Amazing App Can Change You Life</span>
        <span>
          <b>Music Experience</b>
        </span>
      </div>

      <div className="features flex mt-[2rem]">
        <Features
          icon={require("../img/Group 2.png")}
          title={"For Live Music"}
        />
        <Features
          icon={require("../img/music icon.png")}
          title={"For Daily Music"}
        />
        <Features icon={require("../img/Group 4.png")} title={"For Artist"} />
      </div>
    </div>
  );
};

export default Experience;
