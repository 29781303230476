import React from "react";

const Download = () => {
  return (
    <div className="download flex flex-col px-[4rem] py-[5rem] rounded-b-[5rem] h-[48rem] bg-[#020917] mt-[-13rem] justify-center items-center z-[0] relative">
      <img
        src={require("../img/Path 318.png")}
        alt=""
        className="w-[5rem] h-[1rem] mb-[2rem] mt-[6rem]"
      />
      <div className="text-[2.6rem] flex flex-col items-center">
        <span>Download The Best Music</span>
        <span>
          <b>App Now!</b>
        </span>
        <span className="text-[13px] text-[#707070]  mt-[2rem] text-center mb-[3rem]">
          Suspendisse posuere ipsum sed rhoncus commodo. <br /> Vestibulum
          cursus eu sem eget condimentum. <br />
        </span>
      </div>
      <div className="downloadAds flex w-[9.5rem] h-[2.6rem] rounded-[3rem] justify-center">
        <img
          src={require("../img/Google Play.png")}
          alt=""
          className="mr-[2rem] border-[0.5px] border-[#c1bdbd99] rounded-[10px]"
        />
        <img
          src={require("../img/App Store.png")}
          alt=""
          className="border-[0.5px] border-[#c1bdbd99] rounded-[10px]"
        />
      </div>
    </div>
  );
};

export default Download;
